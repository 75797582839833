import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import RequireAuth from "./auth/RequireAuth";
import CheckUserLogged from "./auth/CheckUserLogged";

// context providers
import {AuthContextProvider} from "./contexts/AuthContext";
import {MarginCalculatorContextProvider} from "./contexts/MarginCalculatorContext";
import {ShippingContextProvider} from "./contexts/ShippingContext";
import {DiscountTableContextProvider} from "./contexts/DiscountTableContext";

// pages
import Login from "./pages/Login";
import Maintenance from "./pages/Maintenance";
import MicrosoftLogin from "./pages/MicrosoftLogin";
import Dashboard from "./pages/Dashboard";
import UsersManagement from "./pages/UsersManagement";
import MarginCalculation from "./pages/MarginCalculation";
import PageNotFound from "./pages/PageNotFound";
import ResetPassword from "./pages/ResetPassword";
import SetNewPassword from "./pages/SetNewPassword";
import CartPage from "./pages/CartPage";
import OrderSent from "./pages/OrderSent";
import Carts from "./components/carts/Carts";
import Offers from "./pages/Offers";
import Invoices from "./pages/Invoices";
import Shippings from "./pages/Shippings";
import OfferPage from "./pages/OfferPage";
import OfferCreated from "./pages/OfferCreated";
import Assistance from "./pages/Assistance";
import ReservedConditions from "./pages/ReservedConditions";
import ComplaintSent from "./pages/ComplaintSent";
import OrderPage from "./pages/OrderPage";
import Profile from "./pages/Profile";
import ProfileSent from "./pages/ProfileSent";

// partials
import Header from "./partials/Header";

import {Helmet, HelmetProvider} from "react-helmet-async";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import ScrollToTop from "./components/ScrollToTop";
import {checkBuildVersion} from "./utils/checkBuildVersion";
import {checkSession} from "./utils/checkSession";
import {NewVersionModal} from "./components/NewVersionModal";
import EserviceDown from "./pages/EserviceDown";

import TagManager from 'react-gtm-module';

const queryClient = new QueryClient();

function App() {
    const {t} = useTranslation();

    const preventCopy = (e: any) => {
        const selection: any = document.getSelection();
        e.clipboardData.setData("text/plain", selection.toString().replace(/(\t|\r|\n)/gm, " "));
        e.preventDefault();
    };

    let appTitle = "E-Service | Rothoblaas";
    if (process.env.REACT_APP_ENV !== "production") {
        appTitle = `E-Service ${t("test environment")} | Rothoblaas`;
    }

    const [modalVersionStatus, setModalVersionStatus] = useState<boolean>(false);

    useEffect(() => {
        if (process.env.REACT_APP_GTM_ID) {
            const tagManagerArgs = {
                gtmId: process.env.REACT_APP_GTM_ID
            };
            TagManager.initialize(tagManagerArgs);
        }
    }, []);

    useEffect(() => {
        checkBuildVersion(setModalVersionStatus);
        checkSession();
    }, []);

    return (
        <div className="flex flex-col h-screen" onCopy={(e) => {
            preventCopy(e);
        }}>
            <HelmetProvider>
                <Helmet>
                    <title>{appTitle}</title>
                </Helmet>
            </HelmetProvider>
            <QueryClientProvider client={queryClient}>
                <AuthContextProvider>
                    <MarginCalculatorContextProvider>
                        <ShippingContextProvider>
                            <DiscountTableContextProvider>
                                <BrowserRouter>
                                    <ScrollToTop/>
                                    <Header/>
                                    <NewVersionModal modalVersionStatus={modalVersionStatus}/>

                                    <Routes>
                                        {/* maintenance  */}
                                        <Route path='maintenance' element={<Maintenance/>}/>
                                        <Route path='down' element={<EserviceDown/>}/>

                                        {/* login routes  */}
                                        <Route element={<CheckUserLogged/>}>
                                            <Route path="/" element={<Login/>}/>
                                            <Route path="/microsoft-login" element={<MicrosoftLogin/>}/>

                                            {/* reset password routes  */}
                                            <Route path='/reset-password/'>
                                                <Route path="" element={<ResetPassword/>}/>
                                                <Route path="new-password" element={<SetNewPassword/>}/>
                                            </Route>
                                        </Route>

                                        {/* protected routes  */}
                                        <Route element={<RequireAuth allowedRoles={["can_manage_employees", "can_manage_clients"]}/>}>
                                            <Route path="/users-management" element={<UsersManagement/>}/>
                                        </Route>
                                        <Route element={<RequireAuth allowedRoles={["can_see_margin_calculator"]}/>}>
                                            <Route path="/margin-calculator" element={<MarginCalculation/>}/>
                                        </Route>

                                        <Route element={<RequireAuth/>}>
                                            <Route path="dashboard" element={<Dashboard/>}/>
                                            <Route path="lists" element={<Carts/>}/>
                                            <Route path='carts/*' element={<CartPage/>}/>
                                            <Route path='orders/*' element={<OrderPage/>}/>
                                            <Route path="order-sent/:id" element={<OrderSent/>}/>
                                            <Route path='offers' element={<Offers/>}/>
                                            <Route path='offers/*' element={<OfferPage/>}/>
                                            <Route path="offers/:id/created" element={<OfferCreated/>}/>
                                            <Route path='invoices' element={<Invoices/>}/>
                                            <Route path='shippings' element={<Shippings/>}/>
                                            <Route path='assistance/*' element={<Assistance/>}/>
                                            <Route path='complaint-sent/:id' element={<ComplaintSent/>}/>
                                            <Route path='reserved-conditions/*' element={<ReservedConditions/>}/>
                                            <Route path='profile/*' element={<Profile/>}/>
                                            <Route path='profile-sent' element={<ProfileSent/>}/>
                                        </Route>

                                        {/* page not found route  */}
                                        <Route path='*' element={<PageNotFound/>}/>
                                    </Routes>

                                </BrowserRouter>
                                <ReactQueryDevtools initialIsOpen={false}/>
                            </DiscountTableContextProvider>
                        </ShippingContextProvider>
                    </MarginCalculatorContextProvider>
                </AuthContextProvider>
            </QueryClientProvider>
        </div>
    );
}

export default App;
