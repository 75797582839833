import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdRemoveShoppingCart } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import useCarts from "../../api/carts/useCarts";
import { Loader } from "../Loader";
import { Message } from "../Message";
import Pagination from "../Pagination";
import { Title } from "../Title";
import CartsListTable from "./CartsListTable";
import CartsSearchBar from "./CartsSearchBar";
import SubRowAsync from "./SubRowAsync";
import { Section } from "../Section";

type LocationState = {
    successMsg?: string
};

const Carts = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const locationState = location.state as LocationState;
    const [searchParams, setSearchParams] = useSearchParams();

    // state
    const [successMsg, setSuccessMsg] = useState<string | null>(null);

    const { carts, isLoading, isFetching } = useCarts(Object.fromEntries(searchParams.entries()));

    // SUB ROW RENDER FUNCTION
    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <SubRowAsync row={row} endpoint="carts" />
        ),
        []
    );

    const handlePageChange = (page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        if (locationState?.successMsg) {
            setSuccessMsg(locationState.successMsg);
        }
    }, [locationState]);


    return (
        <div className="py-10 px-8 2xl:px-28">
            {(isLoading || isFetching) && <Loader />}

            <Title variant={Title.variant.primary}>{t("I miei carrelli")}</Title>

            {successMsg ? <Message variant={Message.variant.success} open={!!successMsg} handleCloseMessage={() => { setSuccessMsg(null); }}>{successMsg}</Message> : null}

            <Section title={"Cerca carrello"} className={"mb-10"}>
                <CartsSearchBar setSearchParams={setSearchParams} />
            </Section>

            <>
                {
                    carts?.data?.length > 0
                        ?
                        <div>
                            <CartsListTable
                                renderRowSubComponent={renderRowSubComponent}
                                items={carts?.data}
                                setSearchParams={setSearchParams}
                            />
                            <Pagination
                                currentPage={parseInt(searchParams.get("page") ?? "1", 10)}
                                totalCount={carts?.meta.total}
                                pageSize={10}
                                onPageChange={handlePageChange}
                            />
                        </div>
                        : <div className="flex flex-col justify-center items-center p-8">
                            <MdRemoveShoppingCart className="text-9xl text-gray-200 mb-4" />
                            <span>{t("Non hai nessun carrello salvato al momento")}</span>
                        </div>
                }
            </>
        </div>
    );
};

export default Carts;
