import { useQuery } from "@tanstack/react-query";
import client from "../client";
import { InvoicesSearchParams } from "../../components/invoices/InvoicesSearchBar";

export default function useInvoices(params: InvoicesSearchParams, canFetch: boolean = true) {
    // GET INVOICES
    const fetchInvoices = async (params: InvoicesSearchParams) => (await client.get("invoices", { params })).data;

    const { data: invoices, isLoading, isFetching } = useQuery(
        ["invoices", params],
        () => fetchInvoices(params),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            enabled: canFetch
        }
    );

    return { invoices, isLoading, isFetching };
}
