import { useQuery } from "@tanstack/react-query";
import client from "../client";

type useShippingsParams = {
    page?: number,
    "filter[item_id]"?: string,
    "filter[customer_code]"?: string,
    pageSize?: number
};

export default function useShippings(params: useShippingsParams, canFetch: boolean = true) {
    // GET SHIPPINGS
    const fetchShippings = async (params: useShippingsParams) => (await client.get("/shipping/shippings", { params })).data;

    const { data: shippings, isLoading, isFetching } = useQuery(
        ["shipping/shippings", params],
        () => fetchShippings(params),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            enabled: canFetch,
        }
    );

    return { shippings, isLoading, isFetching };
}
