import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import useSearchBarProducts from "../../api/products/useSearchBarProducts";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import useGetCustomers from "../../api/customers/useGetCustomers";
import { CustomerSelect } from "../commom/CustomerSelect";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import classNames from "classnames";
import { ItemSelect } from "../commom/ItemSelect";
import { UserSelect } from "../commom/UserSelect";
import useUsers from "../../api/users/useUsers";

type CartSearchBarProps = {
    setSearchParams: (nextInit: URLSearchParamsInit, navigateOptions?: {
        replace?: boolean | undefined;
        state?: any;
    } | undefined) => void
};

export type CartSearchParams = {
    "filter[name]"?: string,
    "filter[customer_code]"?: string,
    "filter[technical_sale]"?: string,
    "filter[item_code]"?: string,
    "filter[min_date]"?: string,
    "filter[max_date]"?: string,
    "sort"?: string
    "filter[user_id]"?: string,
    "page"?: string,
};

type formData = {
    "filter[name]"?: string,
    "filter[customer_code]"?: string,
    "filter[technical_sale]"?: string,
    "filter[item_code]"?: string,
    "filter[min_date]"?: string,
    "filter[max_date]"?: string,
    "filter[user_id]"?: string
};

export default function CartsSearchBar({ setSearchParams }: CartSearchBarProps) {
    const { t } = useTranslation();

    // refs
    const itemCodeSelect = useRef<any>();
    const userSelect = useRef<any>();
    const customerSelect = useRef<any>();

    // hooks
    const { roles } = useAuthContext();
    const { loadItemsOptions } = useSearchBarProducts();
    const { loadCustomersOptions } = useGetCustomers();
    const { loadUsersOptions } = useUsers();
    const [searchParams] = useSearchParams();

    // state
    const [formData, setFormData] = useState<CartSearchParams>({});
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

    const handleInputsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (e.target.value.length > 0) {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, [name]: value };
            });
        } else {
            const newForm = { ...formData };
            delete newForm[name as keyof formData];
            setFormData(newForm);
        }
    };

    // ITEM CODE
    const handleItemSearchChange = (inputValue: any, action: any) => {
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, [action?.name]: inputValue?.value?.code };
            });
        } else {
            const newForm = { ...formData };
            delete newForm[action?.name as keyof formData];
            setFormData(newForm);
        }
    };

    // CLIENT
    const handleClientSearchChange = (inputValue: any, action: any) => {
        setSelectedCustomer(inputValue);
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, "filter[customer_code]": inputValue?.code };
            });
        } else {
            const newForm = { ...formData };
            delete newForm["filter[customer_code]"];
            setFormData(newForm);
        }
    };

    // USER
    const handleUserSearchChange = (inputValue: any, action: any) => {
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, "filter[user_id]": inputValue?.id };
            });
        } else {
            const newForm = { ...formData };
            delete newForm["filter[user_id]"];
            setFormData(newForm);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        // return to pagination 1 when the user uses a filter
        setSearchParams({ ...formData, page: "1" });
    };

    const resetForm = () => {
        itemCodeSelect.current.clearValue();
        customerSelect.current.clearValue();
        if (userSelect.current) {
            userSelect.current.clearValue();
        }
        setFormData({});
        setSearchParams({ page: "1" });
    };

    useEffect(() => {
        setFormData(Object.fromEntries(searchParams.entries()));
    }, [searchParams]);

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <fieldset className="m-0">
                    <legend className="sr-only"><span>{t("Ricerca")}</span></legend>

                    <div className="space-y-6">
                        <div className={`grid ${roles(["CS", "BA", "CS_Other", "ASSISTANT"]) ? "grid-cols-2 gap-4" : "grid-cols-1"} whitespace-nowrap`}>
                            {/* CLIENT */}
                            <CustomerSelect
                                label="Seleziona cliente"
                                ref={customerSelect}
                                loadCustomersOptions={loadCustomersOptions}
                                handleChange={handleClientSearchChange}
                                selectedCustomer={selectedCustomer}
                                setSelectedCustomer={setSelectedCustomer}
                            />
                            {
                                roles(["CS", "BA", "CS_Other", "ASSISTANT"]) &&
                                <UserSelect
                                    label="Seleziona utente"
                                    ref={userSelect}
                                    loadCustomersOptions={loadUsersOptions}
                                    handleChange={handleUserSearchChange}
                                />
                            }
                        </div>
                        {/* FIRST ROW */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 whitespace-nowrap">
                            <div>
                                <label>{t("Nome carrello")}</label>
                                <input
                                    value={formData["filter[name]"] ?? ""}
                                    type="text"
                                    name="filter[name]"
                                    onChange={e => handleInputsChange(e)}
                                    placeholder={t("inserisci nome carrello")}
                                />
                            </div>
                            <div>
                                <label>{t("Codice articolo")}</label>
                                <ItemSelect
                                    ref={itemCodeSelect}
                                    name="filter[item_code]"
                                    loadItemsOptions={loadItemsOptions}
                                    handleChange={handleItemSearchChange}
                                />
                            </div>
                        </div>

                        {/* SECOND ROW */}
                        <div className={classNames("grid grid-cols-1 justify-between gap-4 whitespace-nowrap", {
                            "md:grid-cols-2": roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]),
                            "md:grid-cols-3": !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]),
                        })}>
                            {!roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) && <div>
                                <label>{t("Technical Salesman")}</label>
                                <input
                                    value={formData["filter[technical_sale]"] ?? ""}
                                    type="text"
                                    name="filter[technical_sale]"
                                    onChange={e => handleInputsChange(e)}
                                    placeholder={t("inserisci technical salesman")}
                                />
                            </div>}
                            <div>
                                <label>{t("Da")}</label>
                                <input
                                    value={formData["filter[min_date]"] ?? ""}
                                    type="date"
                                    name="filter[min_date]"
                                    onChange={e => handleInputsChange(e)}
                                />
                            </div>
                            <div>
                                <label>{t("A")}</label>
                                <input
                                    value={formData["filter[max_date]"] ?? ""}
                                    type="date"
                                    name="filter[max_date]"
                                    onChange={e => handleInputsChange(e)}
                                />
                            </div>
                        </div>

                        <div className="w-1/3 flex gap-x-2">
                            <Button variant={Button.variant.primary} size={Button.size.full} onClick={handleSubmit}>{t("Cerca")}</Button>
                            <Button
                                variant={Button.variant.text}
                                className="text-xs py-0 flex-1 whitespace-nowrap"
                                icon={Button.icon.reset}
                                onClick={resetForm}
                                type="button"
                            >
                                {t("reset ricerca")}
                            </Button>
                        </div>
                    </div >
                </fieldset >
            </form >
        </div >
    );
}
